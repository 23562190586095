import { loadTFLite } from './defineTFLite'
import '@tensorflow/tfjs-backend-webgl';
import { HMSVideoPlugin, HMSVideoPluginType} from "@100mslive/hms-video";

const TAG = 'VBProcessor';
const pkg = require("../package.json");

export class HMSVirtualBackgroundPlugin implements HMSVideoPlugin {
  background: string | HTMLImageElement
  personMaskWidth: number
  personMaskHeight: number
  isVirtualBackground: boolean
  backgroundImage: HTMLImageElement | undefined
  loadModelCalled: boolean;
  blurValue: any
  tfLite: any
  tfLitePromise: any
  modelName: string

  input: HTMLCanvasElement | null
  output: HTMLCanvasElement | null
  outputCtx: CanvasRenderingContext2D | null
  timerID: number
  imageAspectRatio: number

  personMaskPixelCount: number;
  personMask: ImageData;
  personMaskCanvas: HTMLCanvasElement;
  personMaskCtx: any;

  constructor(background: string) {
    this.background = background;
    this.personMaskWidth = 256;
    this.personMaskHeight = 144;
    this.isVirtualBackground = false;
    this.blurValue = '10px'
    this.loadModelCalled = false;
    this.tfLite = null;
    this.modelName = 'landscape-segmentation';

    this.outputCtx = null;
    this.input = null;
    this.output = null;
    this.timerID = 0;
    this.imageAspectRatio = 1;

    this.personMaskPixelCount = this.personMaskWidth * this.personMaskHeight;
    this.personMask = new ImageData(this.personMaskWidth, this.personMaskHeight);
    this.personMaskCanvas = document.createElement('canvas');
    this.personMaskCanvas.width = this.personMaskWidth;
    this.personMaskCanvas.height = this.personMaskHeight;
    this.personMaskCtx = this.personMaskCanvas.getContext('2d');

    this.log(TAG, "Virtual Background plugin created");
    this.setBackground(this.background);
  }

  async init(): Promise<void> {

    if (!this.loadModelCalled) {
      this.log(TAG, "PREVIOUS LOADED MODEL IS ", this.tfLite);
      this.loadModelCalled = true;
      this.tfLitePromise = loadTFLite();
      this.tfLite = await this.tfLitePromise;
    } else {
      //either it is loading or loaded
      await this.tfLitePromise;
    }
  }

  isSupported(): boolean {
    //support chrome, firefox, edge TODO: check this
    return navigator.userAgent.indexOf("Chrome") != -1 || navigator.userAgent.indexOf("Firefox") != -1 || navigator.userAgent.indexOf("Edg") != -1;
  }

  getName(): string {
    return pkg.name;
  }

  getPluginType(): HMSVideoPluginType {
    return HMSVideoPluginType.TRANSFORM;
  }

  async setBackground(path?: string | HTMLImageElement) {
    if (path !== "") {
      if (path === "none") {
        this.log(TAG,'setting background to :', path);
        this.background = "none";
        this.isVirtualBackground = false;
      } else if (path === "blur") {
        this.log(TAG,'setting background to :', path);
        this.background = "blur";
        this.isVirtualBackground = false;
      } else {
        //Setting virtual background
        const img = await this.setImage(path as HTMLImageElement);
        if(!img || !img.complete || !img.naturalHeight ){
          throw new Error('Invalid image. Provide a valid and successfully loaded HTMLImageElement');
        }
        else{
          this.isVirtualBackground = true;
          this.backgroundImage = img;
        }
      }
    } else {
      this.log(TAG, "Not updating anything using the previous background Settings");
    }
  }

  stop(): void {
    if (this.isVirtualBackground) {
      this.backgroundImage?.removeAttribute('src');
    }
    if(this.outputCtx){
      this.outputCtx!.fillStyle = `rgb(0, 0, 0)`;
      this.outputCtx!.fillRect(0, 0, this.output!.width, this.output!.height);
    }
  }

  processVideoFrame(input: HTMLCanvasElement, output: HTMLCanvasElement, skipProcessing?: boolean): Promise<void> | void {
    if (!input || !output) {
      throw new Error('Plugin invalid input/output');
    }

    this.input = input;
    this.output = output;

    const ctx = output.getContext('2d');
    if (ctx!.canvas.width !== input.width) {
      ctx!.canvas.width = input.width;
    }
    if (ctx!.canvas.height !== input.height) {
      ctx!.canvas.height = input.height;
    }
    this.outputCtx = ctx!;
    this.imageAspectRatio = input.width / input.height;
    if (this.imageAspectRatio <= 0) {
      throw new Error("Invalid input width/height");
    }

    const process = async () => {
      await this.runSegmentation(skipProcessing);
    }

    if (this.background === "none" && !this.isVirtualBackground) {
      this.outputCtx!.globalCompositeOperation = 'copy';
      this.outputCtx!.filter = 'none';
      this.outputCtx!.drawImage(input, 0, 0, input.width, input.height);
    } else {
      process();
    }
  }

  private async setImage(image: HTMLImageElement): Promise<any> {
    image.crossOrigin = 'anonymous';
    return new Promise((resolve, reject) =>{
      image.onload = () => resolve(image)
      image.onerror = reject
    })
  }

  private log(tag: string, ...data: any[]){
    console.info(tag, ...data);
  }

  private resizeInputData(){
    this.personMaskCtx!.drawImage(
        this.input,
        0,
        0,
        this.input!.width,
        this.input!.height,
        0,
        0,
        this.personMaskWidth,
        this.personMaskHeight
    )

    const imageData = this.personMaskCtx!.getImageData(
        0,
        0,
        this.personMaskWidth,
        this.personMaskHeight
    );

    const inputMemoryOffset = this.tfLite._getInputMemoryOffset() / 4;
    for (let i = 0; i < this.personMaskPixelCount; i++) {
      this.tfLite.HEAPF32[inputMemoryOffset + (i * 3)] = imageData.data[i * 4] / 255;
      this.tfLite.HEAPF32[inputMemoryOffset + (i * 3) + 1] = imageData.data[(i * 4) + 1] / 255;
      this.tfLite.HEAPF32[inputMemoryOffset + (i * 3) + 2] = imageData.data[(i * 4) + 2] / 255;
    }

  }
  private infer(skipProcessing?: boolean) {
    if(!skipProcessing){
      this.tfLite._runInference();
    }
    const outputMemoryOffset = this.tfLite._getOutputMemoryOffset() / 4;

    for (let i = 0; i < this.personMaskPixelCount; i++) {
      if (this.modelName === 'meet') {
        const background = this.tfLite.HEAPF32[outputMemoryOffset + (i * 2)];
        const person = this.tfLite.HEAPF32[outputMemoryOffset + (i * 2) + 1];
        const shift = Math.max(background, person);
        const backgroundExp = Math.exp(background - shift);
        const personExp = Math.exp(person - shift);
        // Sets only the alpha component of each pixel.
        this.personMask.data[(i * 4) + 3] = (255 * personExp) / (backgroundExp + personExp);
      }
      else if (this.modelName === 'landscape-segmentation') {
        const person = this.tfLite.HEAPF32[outputMemoryOffset + i]
        this.personMask.data[i * 4 + 3] = 255 * person
      }
    }

    this.personMaskCtx!.putImageData(this.personMask, 0, 0);
  }
  private postProcessing() {
    this.outputCtx!.globalCompositeOperation = 'copy';
    this.outputCtx!.filter = 'none'

    if (this.isVirtualBackground ) {
      this.outputCtx!.filter = 'blur(4px)'
    }
    else {
      this.outputCtx!.filter = 'blur(8px)';
    }
    this.drawPersonMask();
    this.outputCtx!.globalCompositeOperation = 'source-in';
    this.outputCtx!.filter = 'none';
    // //Draw the foreground
    this.outputCtx!.drawImage(this.input!, 0, 0);
    // //Draw the background
    this.drawSegmentedBackground();

  }
  private drawPersonMask() {
    this.outputCtx!.drawImage(
        this.personMaskCanvas,
        0,
        0,
        this.personMaskWidth,
        this.personMaskHeight,
        0,
        0,
        this.output!.width,
        this.output!.height
    )
  }

  private drawSegmentedBackground() {
    this.outputCtx!.globalCompositeOperation = 'destination-over';
    this.outputCtx!.imageSmoothingEnabled = true;
    this.outputCtx!.imageSmoothingQuality = 'high';
    if (this.isVirtualBackground) {
      this.fitImageToBackground();
    }
    else {
     this.addBlurToBackground();
    }
  }

  private async runSegmentation(skipProcessing?: boolean) {
    if (this.tfLite) {
      // const start = performance.now();

      this.resizeInputData();
      await this.infer(skipProcessing);
      this.postProcessing();
      // const end = performance.now();
      // this.log(TAG,"time taken",end -start);
    }
  }

  private fitImageToBackground() {
    let input_width: any, input_height: any, x_offset: any, y_offset: any
    if ((this.backgroundImage!.width / this.backgroundImage!.height) < this.imageAspectRatio) {
      input_width = this.backgroundImage!.width;
      input_height = this.backgroundImage!.width/ this.imageAspectRatio;
      x_offset = 0;
      y_offset = (this.backgroundImage!.height - input_height) / 2;
    }
    else {
      input_height = this.backgroundImage!.height;
      input_width = this.backgroundImage!.height * this.imageAspectRatio;
      y_offset = 0;
      x_offset = (this.backgroundImage!.width - input_width) / 2;
    }
    this.outputCtx!.drawImage(this.backgroundImage!, x_offset, y_offset, input_width, input_height, 0, 0, this.output!.width, this.output!.height);
  }

  private async addBlurToBackground(){
    let blurValue = '15px';
    if (this.input!.width <= 160)
      blurValue = '5px';
    else if (this.input!.width <= 320)
      blurValue = '10px';
    else if (this.input!.width <= 640)
      blurValue = '15px';
    else if (this.input!.width <= 960)
      blurValue = '20px';
    else if (this.input!.width <= 1280)
      blurValue = '25px';
    else if(this.input!.width <= 1920)
      blurValue = '30px';

    this.outputCtx!.filter = `blur(${blurValue})`;
    this.outputCtx!.drawImage(this.input!, 0, 0, this.output!.width, this.output!.height);
  }
}